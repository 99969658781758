import {mapActions, mapGetters} from 'vuex'
import { doQuery, doAction, doUpload } from "@/api/core"
import util from '@/utils/cmyUtils.js'
import CmyPageOld from '@/components/cmy-page/cmy-page-old.vue'
import BbsPersonalArea from '@/components/bbs-personal-area/bbs-personal-area.vue'
import BbsWatchlist from '@/components/bbs-watchlist/bbs-watchlist.vue'
import BbsMessage from '@/components/bbs-message/bbs-message.vue'
import E from "wangeditor";

export default {
    name: "bbs",
    inject:['toBottom'],
    data() {
        return {
            staff_info: {},

            postTop: [],

            posts_pagination: {
                page: 1,
                limit: 10,
                end: false,
            },
            posts: [],

            tab_list_no_title: [
                {
                    key: '0',
                    tab: '最新',
                },
                {
                    key: '1',
                    tab: '热门',
                },{
                    key: '2',
                    tab: '收藏',
                }
            ],
            no_title_key: '0',

            post_types: [
                '话题',
                '提问'
            ],
            forumtypes: [],
            forumtype_index: 0,

            files: [],
            post_content: '',
            // 文章标题
            post_title: '',
            // 帖子类型 0 帖子 1 提问
            post_type: 0,
            // 帖子所属板块
            forumtype_code: '',
            //悬赏积分
            award: 0,

            publish_post_modal: false,

            staff_id_select: undefined,
            personal_area_mode: undefined,

            open_watchlist: false,
            open_message: false
        }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo',
            'token'
        ])
    },
    components: {
        CmyPageOld,
        BbsPersonalArea,
        BbsWatchlist,
        BbsMessage
    },
    mounted() {
        this.queryPostTop()
        this.getStaffInfo()
        this.queryPosts()
        this.initWangEditor()
        this.queryForumTypes()
    },
    methods: {
        openMessage() {
            const that = this
            that.open_message = true
        },
        closeMessage() {
            const that = this
            that.open_message = false
        },
        resetFollowCallback() {
            const that = this
            that.getStaffInfo()
        },
        closeWatchlist() {
            const that = this
            that.open_watchlist = false
        },
        openBBSWatchlist() {
            const that = this
            that.open_watchlist = true
        },
        showMyFav() {
            const that = this
            that.onTabChange('2')
        },
        changeMode(mode) {
            const that = this
            if(mode == that.personal_area_mode) {
                return
            }else {
                that.personal_area_mode = mode
            }
        },
        openBBSPersonalArea(mode) {
            const that = this
            that.staff_id_select = that.staffId
            that.personal_area_mode = mode
        },
        closeBBSPersonalArea() {
            const that = this
            that.staff_id_select = undefined
            that.personal_area_mode = undefined
        },

        cancelFav(post_id) {
            const that = this
            doAction({
                action_code: 'LNFR50',
                param_str2: post_id
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                    that.$message.success('已取消收藏')
                    that.queryPosts()
                    that.staff_info.cnt_favor = that.staff_info.cnt_favor - 1
                }else {
                    that.$message.error(res.errmsg)
                }
            })
        },

        publishPost() {
            const that = this
            var hide = that.$message.loading('正在发布...',0)
            var param = {
                post_type: that.post_type,
                forumtype_code: that.forumtype_code,
                title: util.clearEmoji(that.post_title),
                content: util.clearBackSlash(util.clearEmoji(that.post_content)),
                award: that.award,
                post_staff_id: that.staffId,
            }
            if(that.files.length > 0) {
                param.attach_url1 = that.files[0].url
            }
            if(that.files.length > 1) {
                param.attach_url2 = that.files[1].url
            }
            if(that.files.length > 2) {
                param.attach_url3 = that.files[2].url
            }
            console.log(param)
            doAction({
                action_code: 'LNFR10',
                param_str5: JSON.stringify(param)
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                    that.publish_post_modal = true
                }else {
                    that.$message.error(res.errmsg)
                }
            }).finally(() => {
                hide()
            })
        },
        handleOk() {
            const that = this
            that.publish_post_modal = false
            that.queryPosts()
            that.resetPostEditor()
        },
        resetPostEditor() {
            const that = this
            that.forumtype_index = 0
            that.files = []
            that.post_content = ''
            // 文章标题
            that.post_title = ''
            // 帖子类型 0 帖子 1 提问
            that.post_type = 0
            // 帖子所属板块
            that.forumtype_code = ''
            //悬赏积分
            that.award = 0
        },
        openDoc(url) {
            const that = this
            var a = document.createElement('a')
            a.href = url
            a.target = '_blank'
            a.id='exppub'
            document.body.appendChild(a)
            var alink = document.getElementById('exppub')
            alink.click()
            alink.parentNode.removeChild(a)
        },

        removeFile(index) {
            const that = this
            that.files.splice(index, 1)
        },

        doUploadFile(data) {
            const that = this
            if(that.files.length == 3) {
                that.$message.error('上传附件太多啦')
                return
            }
            var hide = that.$message.loading('上传中...',0)
            doUpload(that.$consts.url.UPLOAD_FORUM_FILE, data.file)
                .then(res => {
                    if (res.errcode >= that.$consts.biz.OK) {
                        that.$message.success('操作成功' ,2)
                        that.files.push({
                            name: res.data.url.substr(res.data.url.lastIndexOf('/')+1),
                            url: res.data.url
                        })
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG, 2)
                    }
                })
                .finally(() => {
                    hide()
                })
        },

        queryForumTypes() {
            const that = this
            doQuery({
                query_code: 'LNFP10',
                page: 1,
                limit: 99
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.forumtypes = res.data
                    that.setForumType(0)
                }
            })
        },
        setForumType(index) {
            const that = this
            that.forumtype_index = index
            that.forumtype_code = that.forumtypes[index].forumtype_code
        },

        //======================================= wangeditor begin ====================================================
        initWangEditor() {
            const that = this
            var editor = new E('#editorElem')
            editor.config.height = 300
            // 编辑器的事件，每次改变会获取其html内容
            editor.config.onchange = html => {
                that.post_content = html;
            };
            editor.config.zIndex = 1
            editor.config.placeholder = ''
            editor.config.menus = [
                // 菜单配置
                'bold',
                'head',
                'fontSize',
                'link',
                'italic',
                'underline',
                'foreColor',
                'image', // 插入图片
            ]
            editor.config.uploadFileName = 'file'
            editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
            editor.config.uploadImgHeaders = {
                'Access-Token': this.token
            }
            editor.config.uploadImgServer = '/api' + that.$consts.url.UPLOAD_QUES_IMG_WANGEDITOR

            editor.create() // 创建富文本实例
            editor.txt.html(that.post_content)
            that.editor = editor
        },

        //======================================= wangeditor end ====================================================


        toPublishPost() {
            const that = this
            that.toBottom()
        },

        toPostDetail(post_id) {
            const that = this
            that.$router.push('/student/postDetail?post_id=' + post_id)
        },

        queryPostTop() {
            const that = this
            doQuery({
                query_code: 'LNFQ19',
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.postTop = res.data
                }
            })
        },
        onTabChange(key) {
            const that = this
            that.no_title_key = key
            that.queryPosts()
        },
        queryPosts(page = 1) {
            const that = this
            var hide = that.$message.loading('拼命加载中...',0)
            doQuery({
                query_code: that.no_title_key == '2' ? 'LNFQ11' : 'LNFQ10',
                param_str3: that.no_title_key == '2' ?  '0' : that.no_title_key,
                page: page,
                limit: that.posts_pagination.limit
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.posts = res.data
                    that.posts_pagination.page = page
                    that.posts_pagination.end = res.data.length < that.posts_pagination.limit
                }
            }).finally(() => {
                hide()
            })
        },
        getStaffInfo() {
            const that = this
            doQuery({
                query_code: 'LNFQ50',
                param_str1: that.staffId,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.staff_info = res.data[0]
                }
            })
        },

    }
}
